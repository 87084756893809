<template>
	<div id="subject" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学科设置</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class="u-f3 left">
						<div class="head u-f-item u-f-jsb">
							<el-checkbox :indeterminate="isIndeterminate" v-model="subjectAll" @change="AllChange">
								<span style="color: #000000;">学科总览</span>
								<span style="color: #A4A4A4;font-size: 12px;margin-left: 5px;">({{ checkedCount }}/{{ subjectList.length }})</span>
							</el-checkbox>
							<el-button size="small" @click="addSubject = true">添加学科</el-button>
						</div>
						<div class="subject-list">
							<el-checkbox-group v-model="subjectVal" @change="subjectChange">
								<el-checkbox v-for="item in subjectList" :label="item.id" :key="item.id">
									<div class="u-f-item u-f-jsb" style="width:16vw;height: 2.5vh;" @mouseover="item.isshow = true" @mouseleave="item.isshow = false">
										<div class="name">{{ item.name }}</div>
										<div class="u-f-item btn" v-if="item.isshow">
											<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShow(item)"></i>
											<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delSubject(item.id)"></i>
										</div>
									</div>
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="u-f10 right">
						<div class="u-f-item school-list" v-for="(items, index) in campus" :key="index">
							<div class="but u-f-justify"><i class="el-icon-arrow-right u-f-justify" :class="active ? 'active' : ''" @click="addToCampus(items.id)"></i></div>
							<div class="school-info">
								<div class="head u-f-item u-f-jsb">
									<div>
										{{ items.name }}
										<span class="span">
											(
											<span>{{ items.data ? items.data.length : 0 }}</span>
											/{{ subjectList.length }})
										</span>
									</div>
									<el-button size="small" style="width: 100px;" v-if="!items.ishow" @click="items.ishow = true">修改</el-button>
									<div class="u-f-item" v-if="items.ishow">
										<el-button size="small" style="width: 100px;" @click="cancelEdit(items.data, index)">取消修改</el-button>
										<el-button size="small" type="primary" style="width: 100px;" @click="finish(index)">完成</el-button>
									</div>
								</div>
								<div class="school-subject-list">
									<div v-for="(item, i) in items.data" :key="i">
										{{ item.name }}
										<i class="iconfont icon-cha" @click="del(index, i)" v-if="items.ishow"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 添加校区 -->
			<el-dialog title="添加学科" :visible.sync="addSubject" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学科名称
						</div>
						<el-input v-model="SubjectName" placeholder="请输入名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addSubject = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加校区 -->
			<el-dialog title="编辑学科" :visible.sync="editSubject" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学科名称
						</div>
						<el-input v-model="editSubjectName" placeholder="请输入名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editSubject = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="edit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue'
export default {
  components: {
    index
  },
  data () {
    return {
      subjectList: [],
      subjectVal: [],
      isIndeterminate: false,
      subjectAll: false,
      active: false,
      checkedCount: 0,
      campus: [],
      addSubject: false,
      SubjectName: '',
      loading: false,
      editSubject: false,
      editSubjectName: '',
      editid: '',
      ids: []
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    stopDefault (e) {
      e.stopPropagation()
    },
    // 学科信息
    getInfo () {
      this.$api.setting.subjectIndex({}).then(res => {
        if (res.data.code == 1) {
          let list = res.data.data.list
          let arr2 = []
          for (let i in list) {
            arr2.push({
              id: list[i].id,
              name: list[i].name,
              pid: list[i].pid,
              school_campus_id: list[i].school_campus_id,
              isshow: false
            })
          }
          this.subjectList = arr2
          let campus = res.data.data.campus
          let arr = []
          for (let i in campus) {
            arr.push({
              data: campus[i].data,
              id: campus[i].id,
              name: campus[i].name,
              ishow: false
            })
          }
          this.campus = arr
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 关闭弹窗
    handleClose (done) {
      done()
    },
    // 修改按钮
    update (index) {
      this.campus[index].ishow = true
    },
    // 全选
    AllChange (val) {
      console.log(val)
      let arr = []
      for (let i in this.subjectList) {
        arr.push(this.subjectList[i].id)
      }
      this.subjectVal = val ? arr : []
      this.checkedCount = val ? arr.length : 0
      this.isIndeterminate = false
      if (this.subjectVal.length) {
        this.active = true
      } else {
        this.active = false
      }
    },
    // 选择学科
    subjectChange (value) {
      console.log(value)
      this.checkedCount = value.length
      if (this.checkedCount == this.subjectList.length) {
        this.subjectAll = true
      } else {
        this.subjectAll = false
      }
      this.isIndeterminate = this.checkedCount > 0 && this.checkedCount < this.subjectList.length
      if (this.subjectVal.length) {
        this.active = true
      } else {
        this.active = false
      }
    },
    // 删除
    del (index, i) {
      this.ids = this.ids.concat(this.campus[index].data[i].id)
      this.campus[index].data.splice(i, 1)
      console.log(this.campus[index].data)
      console.log(i)
      console.log(this.ids)
    },
    // 取消修改
    cancelEdit (item, index) {
      this.getInfo()
      this.ids = []
      this.campus[index].isshow = false
    },
    // 完成修改
    finish () {
      if (this.ids.length) {
        this.$api.setting
          .delSubject({
            id: this.ids
          })
          .then(res => {
            if (res.data.code == 1) {
              this.$message.success('修改成功')
              this.getInfo()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        this.getInfo()
      }
    },
    // 添加学科
    submit () {
      if (this.SubjectName == '' || !this.SubjectName) {
        return this.$message.error('请输入名称')
      }
      this.$api.setting.addSubject({ name: this.SubjectName }).then(res => {
        if (res.data.code == 1) {
          this.$message.success('添加成功')
          this.SubjectName == ''
          this.addSubject = false
          this.getInfo()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 学科编辑
    editShow (item) {
      this.editSubjectName = item.name
      this.editid = item.id
      this.editSubject = true
    },
    edit () {
      if (this.editSubjectName == '' || !this.editSubjectName) {
        return this.$message.error('请输入名称')
      }
      this.$api.setting
        .editSubject({
          name: this.editSubjectName,
          id: this.editid
        })
        .then(res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            this.editSubject = false
            this.getInfo()
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 删除学科
    delSubject (id) {
      this.$confirm('确定要删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$api.setting
          .delSubject({
            id: id
          })
          .then(res => {
            if (res.data.code == 1) {
              this.$message.success('删除成功')
              this.getInfo()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },
    // 添加学科到指定校区
    addToCampus (id) {
      if (this.active) {
        this.$api.setting
          .addDisciplineCampus({
            school_campus_id: id,
            id: this.subjectVal
          })
          .then(res => {
            if (res.data.code == 1) {
              this.$message.success('添加成功')
              this.subjectVal = []
              this.checkedCount = 0
              this.isIndeterminate = false
              this.subjectAll = false
              this.active = false
              this.getInfo()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      }
    }
  }
}
</script>

<style lang="scss">
#subject {
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			.head {
				padding: 15px 30px;
				border-bottom: 1px solid #eeeeee;
			}
			.subject-list {
				padding: 0 30px;
				.el-checkbox {
					display: block;
					margin-top: 15px;
					margin-right: 0;
					.btn {
						i {
							background-color: #f3f3f3;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.el-checkbox:hover {
					.name {
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 82.2vh;
			overflow: hidden;
			overflow-y: scroll;
			padding: 25px 50px 0 25px;
			.school-list {
				margin-bottom: 25px;
				.but {
					margin-right: 25px;
					.el-icon-arrow-right {
						background-color: #f5f7fa;
						width: 35px;
						height: 35px;
						border-radius: 50%;
						color: #d8dddf;
						font-size: 16px;
						cursor: pointer;
					}
					.active {
						background-color: #007aff;
						color: #ffffff;
					}
				}
				.school-info {
					border: 1px solid #eeeeee;
					width: 95%;
					min-height: 25vh;
					.head {
						background-color: #f6f8fb;
						padding: 8px 25px;
						div {
							.span {
								margin-left: 5px;
								color: #8d9da6;
								font-size: 12px;
							}
						}
					}
					.school-subject-list {
						display: flex;
						flex-wrap: wrap;
						padding: 10px 15px 20px 25px;
						div {
							background-color: #f8f8f8;
							color: #8d9da6;
							padding: 5px 0;
							width: 80px;
							text-align: center;
							margin-right: 10px;
							margin-top: 10px;
							cursor: pointer;
							border-radius: 2px;
							position: relative;
							i {
								position: absolute;
								right: 0;
								top: 0;
								background-color: #e8eaec;
								color: #b6bfc5;
								font-size: 12px;
								border-radius: 50%;
								padding: 2px;
							}
							i:hover {
								background-color: #ffffff;
							}
						}
						div:hover {
							background-color: #eeeeee;
						}
					}
				}
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
